/* eslint-disable no-self-compare */
import Order from './Order';

/**
 * Hola hola.
 */
class Criteria {
  filters: Object;

  size: number | null;

  page: number | null;

  order: Order | null;

  /**
   * Constructor.
   *
   * Se le dan valores por defecto a las propiedades en el cuerpo de la función
   * en lugar de hacerlo en la declaración debido a que este TS se compila a
   * ES5, que no permite valores por defecto en los argumentos de las funciones
   * y en caso de usarlos genera un polyfill que obliga de todas formas a
   * pasarle cuatro argumentos a la función.
   *
   * @param filters filtro
   * @param size tamanño
   * @param page pagina
   * @param order orden
   */
  constructor(
    filters?: Object,
    size?: number | null,
    page?: number | null,
    order?: Order | null
  ) {
    this.filters = filters || {};
    this.size = typeof size === 'number' && size === size ? size : null;
    this.page = typeof page === 'number' && page === page ? page : null;
    this.order = order || null;
  }
}

export default Criteria;
