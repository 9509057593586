export default ({
  url,
  data,
  headers
}: {
  url: string;
  data: any;
  headers: any;
}) => ({
  url,
  method: 'POST',
  data,
  transformRequest: [
    (dataInput: any) => {
      return JSON.stringify(dataInput?.data ? dataInput.data : dataInput);
    }
  ],
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...headers
  }
});
