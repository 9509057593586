import { NetworkError } from './NetworkError';

export function isNetworkError(err) {
  return !!err.isAxiosError && !err.response;
}

export function handleError(error) {
  if (isNetworkError(error)) {
    throw new NetworkError();
  }

  const response = error?.response?.data;
  if (response) {
    throw response;
  }

  throw error;
}
