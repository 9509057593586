import { CASE_ATTRIBUTE_MAP } from 'src/client/config/case';

const user = {
  setUser: (userEntity) => {
    return localStorage.setItem('user', JSON.stringify(userEntity));
  },

  getUser: () => {
    return JSON.parse(localStorage.getItem('user'));
  },

  setAuth: (auth) => {
    return localStorage.setItem('auth', JSON.stringify(auth));
  },

  getAuth: () => {
    return JSON.parse(localStorage.getItem('auth'));
  },

  getAccessToken: (attributeCase) => {
    const auth = user.getAuth();
    return auth ? auth[CASE_ATTRIBUTE_MAP.ACCESS_TOKEN[attributeCase]] : null;
  }
};

export default user;
