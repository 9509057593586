import search from './search';
import get from './get';
import post from './post';
import put from './put';
import del from './del';
import login from './login';
import upload from './upload';
import download from './download';

export default { search, get, post, put, del, login, upload, download };
