import { useContext } from 'react';

import { DataContext } from 'src/providers/DataProvider';
import client from 'src/client';
import { handleError } from 'src/client/exceptions/handler';

export default () => {
  const { instance } = useContext(DataContext);

  const get = (url, Model) =>
    client(instance, 'GET', { url, Model })
      .then((response) => response.data)
      .catch(handleError);

  const find = (url, criteria, Model) =>
    client(instance, 'FIND', { url, criteria, Model })
      .then((response) => response.data)
      .catch(handleError);

  const post = (url, data, Model, headers = {}) =>
    client(instance, 'POST', { url, data, Model, headers })
      .then((response) => response.data)
      .catch(handleError);

  const put = (url, data, Model, headers = {}) =>
    client(instance, 'PUT', { url, data, Model, headers })
      .then((response) => response.data)
      .catch(handleError);

  const del = (url, headers = {}) =>
    client(instance, 'DELETE', { url, headers })
      .then((response) => response.data)
      .catch(handleError);

  const login = (url, data, headers = {}) =>
    client(instance, 'LOGIN', { url, data, headers })
      .then((response) => response.data)
      .catch(handleError);

  const upload = (url, data, Model, method, onUploadProgress, headers = {}) =>
    client(instance, 'UPLOAD', {
      url,
      data,
      Model,
      method,
      onUploadProgress,
      headers
    })
      .then((response) => response.data)
      .catch(handleError);

  const download = (url, onDownloadProgress) =>
    client(instance, 'DOWNLOAD', { url, onDownloadProgress })
      .then((response) => response.data)
      .catch(handleError);

  return {
    get,
    post,
    put,
    del,
    find,
    login,
    upload,
    download
  };
};
