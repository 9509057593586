import { buildResponse } from './utils';

export default (data: string, Model: any) => {
  const response = buildResponse(data);
  if (!response?.content) {
    return Model.fromApi(response);
  }
  return response.content.map((value: any) => {
    return Model.fromApi(value);
  });
};
