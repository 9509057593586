import paginatedResultsTransform from 'src/client/transformers/paginatedResultsTransform';
import criteriaTransform from 'src/client/transformers/criteriaTransform';

export default ({
  url,
  criteria,
  Model
}: {
  url: string;
  criteria: any;
  Model: any;
}) => ({
  url,
  method: 'GET',
  params: criteriaTransform(criteria, Model),
  transformResponse: [(data: string) => paginatedResultsTransform(data, Model)]
});
