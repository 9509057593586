class Order {
  column: string;

  direction: string;

  constructor(column: string, direction: string) {
    this.column = column;
    this.direction = direction;
  }

  toApi(Model: any) {
    if (Model && Model?.mapper?.maps?.toApiMap) {
      const map = Model?.mapper?.maps?.toApiMap;
      const mappedColumn = map[this.column]?.key ?? map[this.column];
      return new Order(mappedColumn, this.direction);
    }
    return new Order(this.column, this.direction);
  }

  toString() {
    return `${this.column},${this.direction}`;
  }
}

export default Order;
