export default (config, token) => {
  if (config.url?.includes('/oauth/token') || !token) {
    return config;
  }

  return {
    ...config,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*'
    }
  };
};
