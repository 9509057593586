class ResultList {
  items: Object;

  total: number;

  constructor(items: Object, total: number) {
    this.items = items;
    this.total = total;
  }
}

export default ResultList;
