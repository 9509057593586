import {
  API_VERSION,
  AUTHORIZATION,
  CONTENT_TYPE
} from 'src/client/config/headers';
import { API_TIMEOUT } from 'src/client/config/defaults';
import { configureCase } from 'src/client/config/case';

export const actions = {
  REQUEST_SUCCESS: 'REQUEST_SUCCESS'
};

export const initialState = (options) => ({
  baseUrl: options.baseUrl || 'localhost',
  token: options.token,
  timeout: options.timeout || API_TIMEOUT,
  headerKeys: {
    version: API_VERSION,
    authorization: AUTHORIZATION,
    contentType: CONTENT_TYPE
  },
  attributeCase: configureCase(options.attributeCase)
});

export default function reducer(state, action) {
  switch (action.type) {
    case actions.REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        error: null
      };
    default:
      return { ...state };
  }
}
