/* eslint-disable no-self-compare */
import Logger from 'src/utils/Logger';
import { Criteria } from 'src/model';

import { toApi } from './utils';

export interface Parameters {
  page?: number;
  size?: number;
  sort?: string;
}

export default (criteria: Criteria, Model: any) => {
  const params: Parameters = {
    page:
      typeof criteria.page === 'number' && criteria.page === criteria.page
        ? criteria.page
        : undefined,
    size:
      typeof criteria.size === 'number' && criteria.size === criteria.size
        ? criteria.size
        : undefined
  };

  if (criteria?.order) {
    params.sort = criteria.order.toApi(Model).toString();
  }

  /**
   * Se recomienda el uso del método toApi para definir también el mappeo de
   * los filtros, pero se ofrece la posibilidad de tener un método específico.
   */
  if (criteria?.filters) {
    const mappedFilters = Model?.filterToApi
      ? toApi(criteria.filters, Model.filterToApi)
      : toApi(criteria.filters, Model.toApi);
    Object.assign(params, mappedFilters);
  }

  Logger.info(params, 'Parámetros de Criteria');
  return params;
};
