import { useCallback, useState } from 'react';

import Logger from 'src/utils/Logger';

export default (apiMethod) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [networkError, setShowNetworkError] = useState(false);

  const apiCall = useCallback(
    async (...params) => {
      try {
        setIsLoading(true);
        const response = await apiMethod(...params);
        setIsLoading(false);
        setError(null);
        return response;
      } catch (reason) {
        if (reason.type === 'NETWORK_ERROR') {
          setShowNetworkError(true);
        }

        Logger.error(reason, 'useApi');
        if (reason?.detail || reason.errors) {
          setError(reason);
        }

        setIsLoading(false);
        throw reason;
      }
    },
    [apiMethod]
  );

  return {
    isLoading,
    error,
    networkError,
    apiCall
  };
};
