import bodyRawTransform from 'src/client/transformers/bodyRawTransform';

export default ({
  url,
  data,
  Model,
  headers
}: {
  url: string;
  data: any;
  Model: any;
  headers: any;
}) => ({
  url,
  method: 'PUT',
  data,
  transformRequest: [(dataInput: any) => bodyRawTransform(dataInput, Model)],
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...headers
  }
});
