export default class Permission {
  code: any;

  constructor(source: any) {
    this.code = source;
  }

  static fromApi = (source: any) => source;

  static toApi = (source: any) => source;
}
