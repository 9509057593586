import Logger from 'src/utils/Logger';

export default (error) => {
  if (error.response?.status === 401) {
    // TODO: Refresh or logout.
    // https://stackoverflow.com/questions/47216452/how-to-handle-401-authentication-error-in-axios-and-react
    Logger.error(`El token ha expirado.`);

    // TODO: Usar formato de error correcto cuando esté bien definido
    // eslint-disable-next-line no-throw-literal
    throw {
      errors: {
        __global: [{ code: '401', message: 'You are not authenticated.' }]
      }
    };
  }

  if (error.response?.status === 404) {
    Logger.error(`El recurso ${error.response.data.path} no se ha encontrado.`);
  }

  if (error.response?.data) {
    return Promise.reject(error.response.data);
  }

  throw error;
};
