type Key = string | number;
type Value = string;

interface IKeyValue {
  key: Key;
  value: Value;
  toString: () => string | number;
}

export default class KeyValue implements IKeyValue {
  key: Key;

  value: Value;

  constructor(source: any) {
    this.key = source?.key;
    this.value = source?.value;
  }

  toString() {
    return this.key;
  }
}
