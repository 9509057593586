interface NullSchema {
  nullableFields?: string[];
  nonNullableFields?: string[];
  nullProcessor: Function;
  nullProcessorByField?: { [key: string]: Function };
}

export interface IBaseModel {
  fromApi: (source: any) => {};
  toApi: (source: any) => {};
  denullify: (source: NullSchema) => {};
}

const defaultNullProcessor = (value: any) =>
  value === null || typeof value === 'undefined' ? '' : value;

export default class BaseModel implements IBaseModel {
  constructor(source: any) {
    Object.assign(this, source);
  }

  fromApi = (source: any) => new BaseModel(source);

  toApi = (source: any) => source;

  denullify({
    nullableFields,
    nonNullableFields,
    nullProcessor = defaultNullProcessor,
    nullProcessorByField
  }: NullSchema) {
    const object = { ...this };
    if (nullableFields?.length) {
      nullableFields.forEach((field: string) => {
        object[field] = nullProcessorByField?.[field]
          ? nullProcessorByField[field](object[field])
          : nullProcessor(object[field]);
      });
    } else if (nonNullableFields?.length) {
      Object.keys(this)
        .filter((field: string) => !nonNullableFields.includes(field))
        .forEach((field: string) => {
          object[field] = nullProcessorByField?.[field]
            ? nullProcessorByField[field](object[field])
            : nullProcessor(object[field]);
        });
    }
    return object;
  }
}
