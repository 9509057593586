import formDataTransform from '../transformers/formDataTransform';

export default ({
  url,
  data,
  Model,
  method = 'POST',
  onUploadProgress,
  headers
}: {
  url: string;
  data: any;
  Model: any;
  method: 'POST' | 'PUT';
  onUploadProgress: Function;
  headers: any;
}) => ({
  url,
  method,
  data,
  onUploadProgress,
  transformRequest: [(dataInput: any) => formDataTransform(dataInput, Model)],
  headers: {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data',
    type: 'formData',
    ...headers
  }
});
