import { BadRequestError } from '../exceptions/BadRequestError';

const buildResponse = (data: string) => {
  const response = JSON.parse(data);
  if (response.status === 400) {
    throw new BadRequestError(response);
  }
  return response;
};

const cleanEmptyValues = (object: any): any =>
  typeof object === 'object' && !!Object.keys(object).length
    ? Object.keys(object).reduce((newObject: any, currentProperty: string) => {
        if (Array.isArray(object[currentProperty])) {
          if (object[currentProperty].length) {
            return {
              ...newObject,
              [currentProperty]: object[currentProperty]
            };
          }
        } else if (
          object[currentProperty] instanceof File ||
          object[currentProperty] instanceof Blob
        ) {
          return {
            ...newObject,
            [currentProperty]: object[currentProperty]
          };
        } else if (
          typeof object[currentProperty] === 'object' &&
          object[currentProperty] !== null
        ) {
          const cleanObject = cleanEmptyValues(object[currentProperty]);
          if (Object.keys(cleanObject).length) {
            return {
              ...newObject,
              [currentProperty]: cleanObject
            };
          }
        } else if (
          typeof object[currentProperty] === 'boolean' ||
          !!object[currentProperty]
        ) {
          return {
            ...newObject,
            [currentProperty]: object[currentProperty]
          };
        } else if (
          typeof object[currentProperty] === 'number' &&
          object[currentProperty] === 0
        ) {
          return {
            ...newObject,
            [currentProperty]: object[currentProperty]
          };
        }
        return newObject;
      }, {})
    : object;

const toApi = (data: any, mapper: Function) => cleanEmptyValues(mapper(data));

export { buildResponse, cleanEmptyValues, toApi };
