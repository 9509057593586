export default ({
  url,
  onDownloadProgress
}: {
  url: string;
  onDownloadProgress: Function;
}) => ({
  url,
  method: 'GET',
  responseType: 'blob',
  onDownloadProgress
});
