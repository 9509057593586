type Key = string | number;

interface Role {
  id: number;
  authority: string;
}

interface Permission {
  code: string;
}

interface IBaseUser {
  id: Key | undefined;
  active: boolean | undefined;
  role: Role | undefined;
  permissions: Permission[] | undefined;
  fromApi: (source: any) => {};
  toApi: (source: any) => {};
}

export default class BaseUser implements IBaseUser {
  id: Key | undefined;

  active: boolean | undefined;

  role: Role | undefined;

  permissions: Permission[] | undefined;

  constructor(source: IBaseUser) {
    Object.assign(this, source);
  }

  fromApi = (source: any) => new BaseUser(source);

  toApi = (source: any) => source;
}
