import { useCallback, useState } from 'react';

import Logger from 'src/utils/Logger';
import Order from 'src/model/search/Order';
import Criteria from 'src/model/search/Criteria';

const emptyResults = {
  data: [],
  page: 0,
  totalCount: 0
};

/**
 * @param searchMethod
 * @param searchOnMount
 * @param extraFilters Filtros que se quieren aplicar en la búsqueda inicial y/o
 * que no dependen del formulario de búsqueda.
 */
export default (searchMethod, searchOnMount, extraFilters) => {
  const [filters, setFilters] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [networkError, setShowNetworkError] = useState(false);

  const searchCallback = useCallback(
    async (query) => {
      try {
        if (!filters && !searchOnMount) {
          return emptyResults;
        }
        setIsLoading(true);

        const order =
          query.orderBy &&
          new Order(query.orderBy?.field, query.orderDirection);

        const results = await searchMethod(
          new Criteria(
            { ...extraFilters, ...filters },
            query.pageSize,
            query.page,
            order
          )
        );

        setIsLoading(false);
        return {
          data: results.items,
          page: query.page,
          totalCount: results.total
        };
      } catch (reason) {
        if (reason.type === 'NETWORK_ERROR') {
          setShowNetworkError(true);
        }

        Logger.error(reason, 'useSearch');
        if (reason?.detail || reason.errors) {
          setError(reason);
        }

        setIsLoading(false);
        return emptyResults;
      }
    },
    [extraFilters, filters, searchMethod, searchOnMount]
  );

  return {
    isLoading,
    error,
    networkError,
    filters,
    setFilters,
    searchCallback
  };
};
