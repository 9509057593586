export default (config, accessToken) => {
  if (!config.url?.includes('oauth/token') || !accessToken) {
    return config;
  }

  return {
    ...config,
    headers: {
      Authorization: `Basic ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*'
    }
  };
};
