import { useContext, useState } from 'react';

import Logger from 'src/utils/Logger';
import userStorage from 'src/storage/user';
import useHttpClient from 'src/hooks/useHttpClient';
import { INFO_USER, LOGIN_URL, PERMISSIONS_USER } from 'src/config';
import { DataContext } from 'src/providers/DataProvider';
import { CASE_ATTRIBUTE_MAP } from 'src/client/config/case';
import { BaseUser } from 'src/model';
import Permission from 'src/model/Permission';

export default () => {
  const { apiState } = useContext(DataContext);
  const { get, login: _login } = useHttpClient();
  const [isAuthenticating, setAuthenticating] = useState(false);

  const login = ({
    username,
    password,
    loginUrl = LOGIN_URL,
    userInfoUrl = INFO_USER,
    userPermissionsUrl = PERMISSIONS_USER,
    UserModel = BaseUser,
    PermissionModel = Permission
  }) => {
    setAuthenticating(true);
    return _login(loginUrl, {
      data: {
        [CASE_ATTRIBUTE_MAP.GRANT_TYPE[apiState?.attributeCase]]: 'password',
        username,
        password
      }
    })
      .then((auth) => {
        userStorage.setAuth(auth);
        return auth;
      })
      .then(() => {
        return get(userInfoUrl, UserModel)
          .then((user) => user)
          .catch((reason) => {
            Logger.error(reason);
            // eslint-disable-next-line no-throw-literal
            throw {
              errors: {
                __global: [
                  {
                    code: 'USER_GET_ERROR',
                    message: 'Could not retrieve user.'
                  }
                ]
              }
            };
          });
      })
      .then((user) => {
        return get(userPermissionsUrl, PermissionModel)
          .then((permissions) => new UserModel({ ...user, permissions }))
          .catch((reason) => {
            Logger.error(reason);
            // eslint-disable-next-line no-throw-literal
            throw {
              errors: {
                __global: [
                  {
                    code: 'USER_GET_ERROR',
                    message: 'Could not retrieve user.'
                  }
                ]
              }
            };
          });
      })
      .then((user) => {
        userStorage.setUser(user);
        setAuthenticating(false);
        return user;
      });
  };

  const logout = () => {
    localStorage.removeItem('auth');
    localStorage.removeItem('user');
  };

  const isAuthenticated = () => {
    return true;
  };

  const getAuth = () => {
    return userStorage.getAuth();
  };

  const getUser = () => {
    return userStorage.getUser();
  };

  return {
    authenticating: isAuthenticating,
    isAuthenticated,
    auth: getAuth(),
    user: getUser(),
    login,
    logout
  };
};
