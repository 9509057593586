interface OrderBy {
  field?: string;
}

interface QueryInterface {
  orderBy?: OrderBy;
  orderDirection?: string;
  pageSize?: number;
  page?: number;
}

export default class MaterialTableQuery implements QueryInterface {
  orderBy: OrderBy | undefined;

  orderDirection: string | undefined;

  pageSize: number | undefined;

  page: number | undefined;

  constructor({
    orderByField,
    orderDirection,
    pageSize,
    page
  }: {
    orderByField?: string;
    orderDirection?: string;
    pageSize?: number;
    page?: number;
  }) {
    this.orderBy = orderByField ? { field: orderByField } : undefined;
    this.orderDirection = orderDirection;
    this.pageSize = pageSize;
    this.page = page;
  }
}
