import { ResultList } from 'src/model';

import { buildResponse } from './utils';

export default (data: string, Model: any) => {
  const response = buildResponse(data);

  let items = [];
  if (response?.content) {
    items = response.content.map((value: any) => {
      return Model.fromApi(value);
    });
  }

  const total = response.totalElements ?? 0;
  return new ResultList(items, total);
};
