import methods from 'src/client/methods';

const METHOD_MAP = {
  GET: 'get',
  FIND: 'search',
  PUT: 'put',
  POST: 'post',
  DELETE: 'del',
  LOGIN: 'login',
  UPLOAD: 'upload',
  DOWNLOAD: 'download'
};

const getMethod = (methodKey: string) => methods[METHOD_MAP[methodKey]];

const buildOptions = (methodKey: string, config: any) =>
  getMethod(methodKey)(config);

const buildInstance = (baseInstance: any, method: string, config: any) =>
  baseInstance(buildOptions(method, config));

export default buildInstance;
