import React, { useReducer, useState } from 'react';
import PropTypes from 'prop-types';

import reducer, { initialState } from 'src/reducers/dataProvider';
import Logger from 'src/utils/Logger';

export const DataContext = React.createContext({});

export const DataProvider = ({
  apiConfig,
  instance,
  locale,
  children,
  ...props
}) => {
  const [apiState, dispatch] = useReducer(reducer, apiConfig, initialState);
  const [currentLocale, setLocale] = useState(locale);

  if (!apiState.baseUrl) {
    Logger.error('The server should be a url');
  }

  return (
    <DataContext.Provider
      value={{
        apiState,
        dispatch,
        currentLocale,
        setLocale,
        instance
      }}
      {...props}
    >
      {children}
    </DataContext.Provider>
  );
};

DataProvider.propTypes = {
  /** Código ISO 639-1 del idioma que quieres usar. */
  locale: PropTypes.string
};
