const CASES = {
  SNAKE: 'SNAKE_CASE',
  CAMEL: 'CAMEL_CASE' // in snake case :D
};
const FALLBACK_CASE = CASES.SNAKE;

const isCaseAllowed = (providedCase) => {
  return Object.keys(CASES).includes(providedCase.toUpperCase());
};

export const configureCase = (providedCase) => {
  return isCaseAllowed(providedCase)
    ? CASES[providedCase.toUpperCase()]
    : FALLBACK_CASE;
};

export const CASE_ATTRIBUTE_MAP = {
  GRANT_TYPE: {
    [CASES.SNAKE]: 'grant_type',
    [CASES.CAMEL]: 'grantType'
  },
  ACCESS_TOKEN: {
    [CASES.SNAKE]: 'access_token',
    [CASES.CAMEL]: 'accessToken'
  }
};
