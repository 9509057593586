import axios from 'axios';

import userStorage from 'src/storage/user';

import tokenRequest from './interceptors/tokenInjector';
import authRequest from './interceptors/authInjector';
import unauthorizedResponse from './interceptors/unauthorizedInterceptor';
import { configureCase } from './config/case';

export default ({
  baseUrl,
  paramsSerializer,
  timeout,
  token,
  attributeCase
}) => {
  const instance = axios.create({
    baseURL: baseUrl,
    paramsSerializer
  });

  instance.defaults.timeout = timeout;
  instance.defaults.headers.common = {};
  instance.defaults.headers.common.accept = 'application/json';
  instance.defaults.headers.common['Content-Type'] = 'application/json';

  instance.interceptors.request.use((config) => authRequest(config, token));
  instance.interceptors.request.use((config) => {
    const accessToken = userStorage.getAccessToken(
      configureCase(attributeCase)
    );
    return tokenRequest(config, accessToken);
  });

  instance.interceptors.response.use(null, unauthorizedResponse);
  return instance;
};
